<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="editedItem.Overskrift"
        label="Overskrift"
        maxlength="120"
      ></v-text-field>
      <v-textarea
        v-model="editedItem.Beskrivelse"
        filled
        label="Beskrivelse"
        auto-grow
      ></v-textarea>
      <wtDatetime v-model="editedItem.Dato" label="Dato" onlyDate></wtDatetime>
      <v-text-field
        v-model.number="editedItem.EstimertBesparelse"
        label="Estimert besparelse"
        hide-details
        type="number"
      ></v-text-field>
      <wtMultiSelect
        v-model="editedItem.itemsErfaringKatogori"
        item-text="Description"
        item-value="Value"
        :items="KatogoriItems"
        label="Erfaringskatogori"
      ></wtMultiSelect>
      <wtMultiSelect
        v-model="editedItem.itemsErfaringVedlegg"
        item-text="Description"
        item-value="Value"
        :items="VedleggMetaItems"
        label="Vedlegg"
      ></wtMultiSelect>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";
import wtDatetime from "../components/wtDatetime.vue";
import wtMultiSelect from "../components/wtMultiSelect.vue";

export default {
  extends: KeyValue,
  name: "dlgErfaring",
  components: {
    wtDatetime,
    wtMultiSelect,
  },

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    keyTaskID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
